import * as React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { Container } from '../../../layout/Container';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { Section } from '../../../layout/Section';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';
import { Button } from '../../../ui/button/Button';
import NoMatch from '../../404Old';

import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import { DEPARTMENT_EXIST_QUERY } from '../../../../../client/__graphql__/cluster/queries/department/exist';
import {
  DepartmentExistQuery,
  DepartmentExistQueryVariables
} from '../../../../__types__/generated/cluster';
import { client } from '../../../../__graphql__/clientCluster';
import { ENV_CONFIG } from '../../../../__config__/env';

const About: React.FC = () => {
  const params = useParams<{ urltag: string }>();
  const history = useHistory();

  const { data, error, loading } = useQuery<
    DepartmentExistQuery,
    DepartmentExistQueryVariables
  >(DEPARTMENT_EXIST_QUERY, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        alias: params.urltag,
        brandId: ENV_CONFIG?.BRAND_ID
      }
    }
  });

  const { fadeIn } = useFadeTransition({
    loaded: !loading
  });

  if (error?.graphQLErrors && error?.graphQLErrors?.length > 0) {
    return <NoMatch />;
  }

  return (
    <Container style={{ paddingTop: '100px', minHeight: '600px' }}>
      <Section style={fadeIn}>
        <Heading tag="h1" center={true}>
          Mer om oss
        </Heading>
        <Paragraph center={true} style={{ marginTop: 0 }}>
          Her finner du mer informasjon om PrivatMegleren{' '}
          {data?.department?.name}
        </Paragraph>
        <ButtonGroup
          className={params.urltag === 'hovedkontor' ? 'hovedkontor' : ''}
        >
          <Button
            type="primary"
            colorTheme="gold"
            fullWidth={true}
            onClick={() => {
              history.push(`/${params.urltag}/om-oss/beskrivelse`);
            }}
          >
            Om oss
          </Button>
          {params.urltag !== 'hovedkontor' ? (
            <Button
              type="primary"
              colorTheme="gray"
              fullWidth={true}
              onClick={() => {
                history.push(`/${params.urltag}/prislistekjop`);
              }}
            >
              Prisliste kjøpsmegling
            </Button>
          ) : null}
          {params.urltag === 'hovedkontor' ? (
            <Button
              type="primary"
              colorTheme="gray"
              fullWidth={true}
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.open(
                    'https://privatmegleren.no/leveransebeskrivelse-selge-bolig',
                    '_blank'
                  );
                }
              }}
              center={true}
            >
              Vår leveranse
            </Button>
          ) : null}

          {params.urltag !== 'hovedkontor' ? (
            <Button
              type="primary"
              colorTheme="gray"
              fullWidth={true}
              onClick={() => {
                history.push(`/${params.urltag}/om-oss/leveransebeskrivelse`);
              }}
            >
              Prisliste salg
            </Button>
          ) : null}
        </ButtonGroup>
      </Section>
      <BackgroundLoad
        opacity={0.3}
        position="center"
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
      />
    </Container>
  );
};

export default About;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  width: 100%;

  @media all and (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }

  &.hovedkontor {
    grid-template-columns: 1fr;
    justify-content: center;

    @media all and (min-width: 800px) {
      grid-template-columns: 430px;
    }
  }
`;
