import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { Container } from '../../layout/Container';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Section } from '../../layout/Section';
import { BackgroundLoad } from '../../layout/BackgroundLoad';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { ButtonGroupColumn } from '../../ui/button/ButtonGroupColumn';
import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { Seo } from '../../../utils/seo/Index';
import { Modal } from '../../ui/modal/Modal';
import { VideoPlayer } from '../../ui/video-player/Index';
import { PlayButton } from '../../ui/play-button/Index';

const Index: React.FC<RouteComponentProps> = ({ location }) => {
  const [showVideo, setShowVideo] = React.useState(false);

  const { fadeIn } = useFadeTransition({
    immidiate: true
  });
  return (
    <>
      <Seo
        title="Om oss - PrivatMegleren"
        description="Som kunde hos PrivatMegleren får du en skreddersydd prosess tilpasset dine behov og ønsker"
        url={location.pathname}
      />
      <Container style={{ paddingTop: '100px', minHeight: '600px' }}>
        <Modal
          id="portals"
          noBodyScroll={true}
          showModal={showVideo}
          overlay={true}
          showCloseButton={true}
          onClickClose={() => setShowVideo(false)}
          onClickOutside={() => setShowVideo(false)}
        >
          <VideoPlayer
            type="vimeo"
            url="https://player.vimeo.com/video/428062625?color=e8c893&title=0&byline=0&portrait=0&controls=1"
          />
        </Modal>
        <Section style={fadeIn}>
          <Heading tag="h1" center={true}>
            Om oss
          </Heading>
          <Paragraph center={true}>
            PrivatMegleren kombinerer landets fremste eiendomsmeglere med et av
            bransjens mest
            <br /> kraftfulle verktøy innen markedsføring.
          </Paragraph>
          <ButtonGroup>
            <ButtonGroupColumn className="space-bottom full-width">
              <Button
                type="primary"
                colorTheme="gold"
                fullWidth={true}
                as="link"
                to="/om-oss/konsept"
                center={true}
              >
                Mer om PrivatMegleren
              </Button>
            </ButtonGroupColumn>
            <ButtonGroupColumn className="space-bottom full-width">
              <Button
                type="primary"
                colorTheme="gray"
                fullWidth={true}
                center={true}
                as="a"
                href="https://privatmegleren.no/content/selge-bolig-tips-rad"
              >
                Tips og råd ved salg
              </Button>
            </ButtonGroupColumn>
          </ButtonGroup>
          <ButtonGroup>
            <ButtonGroupColumn className="space-bottom full-width">
              <Button
                type="primary"
                colorTheme="gray"
                fullWidth={true}
                center={true}
                as="link"
                to="/hovedkontor"
              >
                Hovedkontor
              </Button>
            </ButtonGroupColumn>
            <ButtonGroupColumn className="space-bottom full-width">
              {/*               <Button
                type="primary"
                colorTheme="gray"
                fullWidth={true}
                center={true}
                as="a"
                href="https://privatmegleren.no/hvaerverdivurdering"
              >
                Hva er en E-takst (verdivurdering)?
              </Button> */}
            </ButtonGroupColumn>
          </ButtonGroup>
          <ButtonGroup className="center" style={{ marginTop: '20px' }}>
            <PlayButton
              label="Se brandvideo"
              onClick={() => setShowVideo(true)}
            />
          </ButtonGroup>
        </Section>
        <BackgroundLoad
          opacity={0.25}
          position="center -175px"
          url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
        />
      </Container>
    </>
  );
};

export default Index;
